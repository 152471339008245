import React, { useEffect, useState } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Typewriter from "typewriter-effect";
import { introdata, meta } from "../../content_option";
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import BookingCalendar from 'react-booking-calendar';
import './modal.css'
import Calendar from 'react-calendar';
import { FaWindowClose } from 'react-icons/fa'
import Select from "react-dropdown-select";
import moment from 'moment'
import withFirebaseAuth from 'react-with-firebase-auth'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { withStyles } from "@material-ui/core/styles";
import { CheckBox } from "@material-ui/icons";
import { Socialicons } from "../../components/socialicons";
import {getFirestore, collection, addDoc} from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyDF7dEa4O578cp6zIdD_poXbN8KLRaDzJw",
  authDomain: "rahulmattihalli-7bbc7.firebaseapp.com",
  projectId: "rahulmattihalli-7bbc7",
  storageBucket: "rahulmattihalli-7bbc7.appspot.com",
  messagingSenderId: "980452536148",
  appId: "1:980452536148:web:3c407b11697f3d0820e2c1",
  measurementId: "G-RHK6RNTT1X",

};
const app = firebase.initializeApp(firebaseConfig)
firebase.analytics()
const db = firebase.firestore()
export const Home = () => {
 const signInWithGoogle = () => {
  
  firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider()).then((res) => {
    localStorage.setItem('email',res.user.email)
    localStorage.setItem('name',res.user.displayName)
    setCalendarModal(true)
  }).catch((error) => {
    console.log(error.message)
  })
}
const [availableDates, setAvailableDates] = useState([])
const [bookedDates,setBookedDates] = useState([])
const [bookedEmail,setBookedEmails] = useState([])
const getSlotDates = async ()=>{
try{
 
  const response=db.collection('availableSlots');
  const data=await response.get();
  let docSnapshots = data.docs
  let availableSlots = [];
  for (let i in docSnapshots) {
    const doc = docSnapshots[i].data();
    availableSlots.push(doc.date)
    // Check for your document data here and break when you find it
}
setAvailableDates(availableSlots)
}catch(err){
  console.log(err)
  }
}

const getBookedDates = async ()=>{
  try{
   
    const response=db.collection('bookedSlots');
    const data=await response.get();
    let docSnapshots = data.docs
    let bookedSlots = [];
    let emails = [];
    for (let i in docSnapshots) {
      const doc = docSnapshots[i].data();
      bookedSlots.push(doc.date)
      emails.push(doc.email)
      // Check for your document data here and break when you find it
  }
  setBookedEmails(emails)
  setBookedDates(bookedSlots)
  }catch(err){
    console.log(err)
    }
  }

useEffect(() => {
getSlotDates()
getBookedDates()
}, [])

  const [value, onChange] = useState(new Date());
  const [snackbar,setSnackbar] = useState(false);
  const [message,setMessage] = useState("")
  const [gender,selectGender] = useState(null)
  const [height,setHeight] = useState(5.8)
  const [weight,setWeight] = useState(75)
  const [description,setDescription] = useState("")
  const [termsAgree,setAgreement] = useState(false)
  const options = [
    {
    label:"Male",
    value:"Male"
    },
    {
      label:"Female",
      value:"Female"
      },
      {
        label:"Other",
        value:"Other"
        }
  ];

  
  

  const showSnackbar = async(message)=>{
    setMessage(message)
    setSnackbar(true)
    setTimeout(()=>{
      setSnackbar(false)
    },3000)
  }

  const bookSlot = async ()=>{
    if(!gender){
      showSnackbar("Please select gender")
    }
    else if(description==""){
      showSnackbar("Please describe your fitness problem and goals")
    }
    else{
      try{
      setDetailModal(false)
      const docRef = await addDoc(collection(db,"bookedSlots"),{
        gender:gender,
        email:localStorage.getItem('email'),
        name:localStorage.getItem('name'),
        description:description,
        height:height,
        weight:weight,
        date:moment(value).format("DD-MM-YYYY")
      })
      getBookedDates()
      showSnackbar("Slot request sent, please wait for confirmation mail")
    }catch(err){
    console.log(err)
    showSnackbar("something went wrong! try again later")
    }
  }
  }

  const styles = {
    snackbarStyleViaContentProps: {
      backgroundColor: "orange"
    },
    snackbarStyleViaNestedContent: {
      backgroundColor: "lightgreen",
      color: "black"
    }
  };

  const checkSlot=(date)=>{
    console.log(date)
    if(bookedEmail.includes(localStorage.getItem('email'))){
      showSnackbar("You have already booked slot!")
    }
    else if(bookedDates.find(x=>x===moment(date).format("DD-MM-YYYY"))){
      showSnackbar("This slot already booked")
    }
    else if(availableDates.find(x=>x===moment(date).format("DD-MM-YYYY"))){
      window.scroll(0,0)
      setDetailModal(true)
    }
    else{
      showSnackbar("No slots available on selected date")
    }
  }

  const [calendarModal, setCalendarModal] = useState(false)
  const [detailModal,setDetailModal] = useState(false)
  return (
    <HelmetProvider>
      <section id="home" className="home">
      <div>
      {snackbar && <Snackbar
        anchorOrigin={{
          vertical: "center",
          horizontal: "center"
        }}
        open={true}
        ContentProps={{
          "aria-describedby": "message-id",
          className: {
            backgroundColor: "lightgreen",
          color: "black"
        }
        }}
        message={
          <span id="message-id">
            <div>{message}</div>
          </span>
        }
      />}
    </div>
        <Helmet>
          <meta charSet="utf-8" />
          <title> {meta.title}</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <div className="intro_sec d-block d-lg-flex align-items-center ">
          <div
            className="h_bg-image order-1 order-lg-2 h-100 "
            style={{ backgroundImage: `url(${introdata.your_img_url})` }}
          >
            <div style={{position:"absolute",bottom:10,right:10,backgroundColor:"rgb(0,0,0,0.7)",padding:10,borderRadius:5}}>
              <h5>Squats: 140kg</h5>
              <h5>Deadlift: 190kg</h5>
              <h5>Bench Press:110kg</h5>
              </div>
          </div>
          <div className="text order-2 order-lg-1 h-100 d-lg-flex justify-content-center">
            <div className="align-self-center ">
              <div className="intro mx-auto">
                <h2 className="mb-1x">{introdata.title}</h2>
                <h1 className="fluidz-48 mb-1x" style={{color:"green"}}>
                  <Typewriter
                    options={{
                      strings: [
                        introdata.animated.first,
                        introdata.animated.second,
                        introdata.animated.third,
                      ],
                      autoStart: true,
                      loop: true,
                      deleteSpeed: 10,
                    }}
                  />
                </h1>
               <p className="mb-1x">{introdata.description}</p>
                <div className="intro_btn-action pb-5">
                  <Link to="/facts" className="text_2">
                    <div id="button_p" className="ac_btn btn mb-2 ">
                      Fitness Facts
                      <div className="ring one"></div>
                      <div className="ring two"></div>
                      <div className="ring three"></div>
                    </div>
                  </Link>
                    <div id="button_h" className="ac_btn btn mb-2" onClick={()=>{if(localStorage.getItem('email')){
                      setCalendarModal(true)
                    } 
                    else{
                    signInWithGoogle()
                    }
                  
                    }}>
                     Book consultation
                      <div className="ring one"></div>
                      <div className="ring two"></div>
                      <div className="ring three"></div>
                    </div>
                    <Link to="/diet" className="text_2">
                    <div id="button_p" className="ac_btn btn mb-2 ">
                      My Diet
                      <div className="ring one"></div>
                      <div className="ring two"></div>
                      <div className="ring three"></div>
                    </div>
                  </Link>
                </div>
                {!calendarModal && !detailModal && <Socialicons />}
              </div>
             {calendarModal &&  <Calendar onChange={onChange} onClickDay={(date)=>{ checkSlot(date)}}  value={value}
             tileClassName={({ date, view }) => {
               if(bookedDates.find(x=>x===moment(date).format("DD-MM-YYYY"))){
                return  'booked'
               }
              else if(availableDates.find(x=>x===moment(date).format("DD-MM-YYYY"))){
               return  'highlight'
              }
              else
              return 'disabled'
            }}
        
            
            tileDisabled={({ date }) => date.getDay() === 0}
        
            /*maxDate={new Date(2020, 1, 0)}</div>*/
             minDate={
              new Date()
            }
             />}
            
             <Modal
        isOpen={detailModal}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        contentLabel="Book slot"
        style={{
          overlay: {
            position: 'absolute',
            zIndex: 1020,
            top: 10,
            left: 10,
            right:10,
            width: 'auto',
            height: 'auto',
            background: 'rgba(0, 0, 0, 0.75)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            background: 'white',
            width: '40rem',
            maxWidth: 'calc(100vw - 5rem)',
            maxHeight: 'calc(100vh - 2rem)',
            overflowY: 'scroll',
            position: 'relative',
            left:0,
            right:0,
            border: '1px solid #ccc',
            borderRadius: '0.3rem',
            color:"black",

            
          }}}
      >
        <div style={{position:"absolute",right:10,top:5}} onClick={()=>{setDetailModal(false)}}>
          <FaWindowClose></FaWindowClose>
        </div>
        <h3>Hello {localStorage.getItem('name')}</h3>
        <div style={{borderWidth:1,borderStyle:"solid",borderColor:"#e5e5e5",padding:10,marginTop:5,marginBottom:5,backgroundColor:"#fafafa",}}>{localStorage.getItem('email')}</div>
        <div style={{fontSize:20, marginBottom:10}}>You have selected slot: {new Date(value).toLocaleDateString()} - 5:00pm </div>
        <form>
        <Select options={options} onChange={(val)=>{selectGender(val[0].value)}} placeholder={"Select gender..."}>
          </Select>
          <label style={{margin:10}}>
            Your Height (Ft):
            </label>
        <input type="number" value={height} step={0.1}  onChange={(event)=>{setHeight(event.target.value)}} style={{margin:10,width:60,height:50,borderRadius:5,padding:5}}/>
        <label style={{margin:10}}>
            Your Weight (Kg):
            </label>
        <input type="number" value={weight} onChange={(event)=>{setWeight(event.target.value)}} style={{margin:10,width:60,height:50,borderRadius:5,padding:5}}/>
        <div style={{fontSize:20, marginBottom:10}}>Please describe your fitness problems and goals</div>
          <input value={description} onChange={(event)=>{setDescription((value)=>{return event.target.value})}} style={{width:100+"%",height:100,marginTop:10,marginBottom:10,padding:10}} />
         <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"row"}}>
         <input
            name="isGoing"
            type="checkbox"
            checked={termsAgree}
            onChange={(e)=>{setAgreement((prev)=>{return !prev})}}
            />
         <div style={{marginLeft:5}}>I agree to all  <Link to="/terms" style={{color:"blue"}}>Terms and conditions</Link></div>
           </div> 
          <div style={{display:"flex",justifyContent:"center",alignItems:"center",width:100+"%"}}>
          <button  onClick={(e)=>{e.preventDefault(); if(termsAgree) bookSlot(); else showSnackbar("Please agree to terms and conditions")}} className="button" style={{margin:10,textAlign:"center",justifyContent:"center",alignItems:"center"}}>Book slot</button>
          </div>
        </form>
      </Modal>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};
