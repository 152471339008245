const logotext = "RAHUL MATTIHALLI";
const meta = {
    title:"Rahul Mattihalli",
    description: "Hello I'm Rahul, A vegan body builder and a Techno-Entrepreuneur. ",
};

const introdata = {
    title: "I’m here to help",
    animated: {
        first: "Can't lose weight?",
        second: "Can't gain muscles?",
        third: "Don't know what to eat to get fit?",
    },
    description: "I am a vegan bodybuilder and I have around 5 years of experience in fitness space with over a million views in quora on fitness topics.",
    your_img_url: "https://i.postimg.cc/QdS25Jpq/image.png",
};

const dataabout = {
    title: "abit about my self",
    aboutme: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent quis dolor id ligula semper elementum feugiat pretium nulla. Nunc non commodo dolor. Nunc mollis dignissim facilisis. Morbi ut magna ultricies.",
};
const worktimeline = [{
        jobtitle: "Designer of week",
        where: "YAdfi",
        date: "2020",
    },
    {
        jobtitle: "Designer of week",
        where: "Jamalya",
        date: "2019",
    },
    {
        jobtitle: "Designer of week",
        where: "ALquds",
        date: "2019",
    },
];

const skills = [{
        name: "Python",
        value: 90,
    },
    {
        name: "Djano",
        value: 85,
    },
    {
        name: "Javascript",
        value: 80,
    },
    {
        name: "React",
        value: 60,
    },
    {
        name: "Jquery",
        value: 85,
    },
];

const services = [{
        title: "UI & UX Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Mobile Apps",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Wordpress Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
];

const dataportfolio = [{
        img: "https://i.postimg.cc/7Yy9frpr/IMG-0277-Copy-2.jpg",
        desctiption: "The more muscle mass you have, the more fat your body burns while resting",
        link: "#",
    },
    {
        img: "https://i.postimg.cc/jdzf9WgG/IMG-0514-Copy-2.jpg",
        desctiption: "Cardiovascular exercise helps create new brain cells. This enhances brainpower and brain activity.",
        link: "#",
    },
    {
        img: "https://i.postimg.cc/T14j167y/IMG-0233.jpg",
        desctiption: "Exercising increases the production of cells that are responsible for learning and memory",
        link: "#",
    },
    {
        img: "https://i.postimg.cc/7LBt2tSM/IMG-1549-Copy-1.jpg",
        desctiption: "If you run at a 10 minute per mile pace, you can burn 104.3 calories per mile.",
        link: "#",
    },
    {
        img: "https://i.postimg.cc/d0GgZj45/IMG-20190907-223427-2-Copy.jpg",
        desctiption: "If you exercise 3 times a week for 45 minutes, you can help prevent signs of ageing.",
        link: "#",
    },
    {
        img: "https://i.postimg.cc/tTTtGXZF/IMG-1739.jpg",
        desctiption: "Having more muscle than fat means you can consume more calories.",
        link: "#",
    },

    {
        img: "https://i.postimg.cc/tCq8Vtrp/IMG-0612-2.jpg",
        desctiption: "Exercising can help ease your mind and rejuvenate your body. Working out will make you feel great and boost your confidence.",
        link: "#",
    },
    {
        img: "https://i.postimg.cc/mr6VDzGm/IMG-0504-1.jpg",
        desctiption: "If you are someone who has trouble sleeping or staying asleep, then working out is your answer. Exercise helps to clear your head and helps you feel relaxed.",
        link: "#",
    },
    {
        img: "https://i.postimg.cc/tTjJFDyJ/IMG-20190714-185652-01-Copy-1.jpg",
        desctiption: "Sweat releases dirt through your pores, which reduces acne and breakouts. Workouts improve the overall look of your skin.",
        link: "#",
    }
];



const contactConfig = {
    YOUR_EMAIL: "name@domain.com",
    YOUR_FONE: "(555)123-4567",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi vehicula eu nunc et sollicitudin. Cras pulvinar, nisi at imperdiet pharetra. ",
    YOUR_SERVICE_ID: "service_id",
    YOUR_TEMPLATE_ID: "template_id",
    YOUR_USER_ID: "user_id",
};

const socialprofils = {
    github: "https://github.com",
    facebook: "https://facebook.com",
    linkedin: "https://www.linkedin.com/in/rahul-mattihalli-b6aa501b0/",
    twitter: "https://twitter.com/RahulMattihalli",
    quora: "https://www.quora.com/profile/Rahul-Mattihalli",
    twitch: "https://twitch.com",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};