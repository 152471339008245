import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from "react-helmet-async";
import {  Row, Col } from "antd";
import AnimatedNumber from "animated-number-react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

function Diet() {
    const [width, setWidth]   = useState(window.innerWidth);
const [height, setHeight] = useState(window.innerHeight);
console.log(width)
const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
}
useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
}, []);
    const formatValue = (value) => value.toFixed(0);
    return (
        <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
            <h1>Detailed Diet</h1>
            <h2 style={{color:"goldenrod"}}>Morning First Breakfast</h2>
            <Row style={{display:"flex",flexDirection: width<768 ? "column":"row",justifyContent:"space-evenly",alignItems:"center",width:80+"%",margin:20}}>
                <Col  md={12} >
                   <div data-aos="flip-left" style={{display:"flex",flexDirection:"row"}}>
                  <div style={{display:"flex",flexDirection:"column"}}>
                   <img src={"https://i.postimg.cc/fbpgNMJ7/museli.jpg"} style={{width:180,height:250,borderRadius:10,margin:5}}></img>
                   <button style={{margin:5}}>
                       <a target={"_blank"} href="https://www.flipkart.com/yogabar-dark-chocolate-cranberry-muesli-pouch/p/itm518f4a6e5c6d0">Checkout</a></button>
                   </div>
                   <div style={{display:"flex",flexDirection:"column"}}>
                   <img src={"https://i.postimg.cc/GmNjBqdd/soymilk.jpg"} style={{width:180,height:250,borderRadius:10,margin:5}}></img>
                   <button style={{margin:5}}>
                       <a target={"_blank"} href="https://www.amazon.in/Sofit-Soya-Milk-Sugar-Free/dp/B00SGUIYCC/">Checkout</a>
                       </button>
                      </div>
                       </div> 
                </Col>
                <Col md={12}>
                   <div data-aos="slide-down" >
                   <h4 style={{textAlign:"center",marginTop:10,margin:10,paddingLeft:5,paddingRight:5}}>Dark chocolate Museli that consists of quinoa,oats,brown rice,flax & chia seeds with soy milk, high in protein, antioxidants and omega-3</h4>
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Calories</p>
                      <div style={{fontSize:30,color:'green'}}>
                      <AnimatedNumber value={400} formatValue={formatValue} duration={3000} />
                      Kcal
                      </div>
                   </div>
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Protein</p>
                      <div style={{fontSize:30,color:"#ff7043"}}>
                      <AnimatedNumber value={18} formatValue={formatValue} duration={3000} />
                      g
                      </div>
                   </div>
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Fat</p>
                      <div style={{fontSize:30,color:"#f44336"}}>
                      <AnimatedNumber value={20} formatValue={formatValue} duration={3000} />
                     g
                      </div>
                   </div>
                       </div> 
                </Col>
            </Row>
        <div style={{width:90+"%",borderWidth:0.1,borderStyle:"solid",borderColor:"darkslategray",marginBottom:10}}></div>
            <h2 style={{color:"goldenrod"}}>Morning Second Breakfast</h2>
            <Row style={{display:"flex",flexDirection: width<768 ? "column":"row",justifyContent:"space-evenly",alignItems:"center",width:80+"%",margin:20}}>
                <Col  md={12} >
                   <div data-aos="flip-left"  style={{display:"flex",flexDirection:"row"}}>
                  <div style={{display:"flex",flexDirection:"column"}}>
                   <img src={"https://i.postimg.cc/SN46R4xG/Screenshot-2022-06-03-at-5-04-36-PM.png"} style={{width:180,height:250,borderRadius:10,margin:5}}></img>
                   <button style={{margin:5}}>
                       <a target={"_blank"} href="https://nutrabay.com/product/nutrabay-pure-100-soy-protein-isolate">Checkout</a></button>
                   </div>
                   <div style={{display:"flex",flexDirection:"column"}}>
                   <img src={"https://i.postimg.cc/HxVQh4t3/17099547052-8dc47c1c0d-z.jpg"} style={{width:180,height:250,borderRadius:10,margin:5}}></img>
                   <button disabled style={{margin:5}}>
                       <a aria-disabled >Checkout</a>
                       </button>
                      </div>
                       </div> 
                </Col>
                <Col md={12}>
                   <div data-aos="slide-down" >
                   <h4 style={{textAlign:"center",marginTop:10,margin:10,paddingLeft:5,paddingRight:5}}>Proper Indian breakfast that is rich in calories and Protein shake</h4>
                  
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Calories</p>
                      <div style={{fontSize:30,color:'green'}}>
                      <AnimatedNumber value={350} formatValue={formatValue} duration={3000} />
                      Kcal
                      </div>
                   </div>
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Protein</p>
                      <div style={{fontSize:30,color:"#ff7043"}}>
                      <AnimatedNumber value={30} formatValue={formatValue} duration={3000} />
                      g
                      </div>
                   </div>
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Fat</p>
                      <div style={{fontSize:30,color:"#f44336"}}>
                      <AnimatedNumber value={1} formatValue={formatValue} duration={3000} />
                     g
                      </div>
                   </div>
                       </div> 
                </Col>
            </Row>
            <div style={{width:90+"%",borderWidth:0.1,borderStyle:"solid",borderColor:"darkslategray",marginBottom:10}}></div>
            <h2 style={{color:"goldenrod"}}>Lunch</h2>
            <Row style={{display:"flex",flexDirection: width<768 ? "column":"row",justifyContent:"space-evenly",alignItems:"center",width:80+"%",margin:20}}>
                <Col  md={12} >
                   <div data-aos="flip-left"  style={{display:"flex",flexDirection:"row"}}>
                  <div style={{display:"flex",flexDirection:"column"}}>
                   <img src={"https://i.postimg.cc/wvw46Bh3/Screenshot-2022-06-03-at-5-28-36-PM.png"} style={{width:180,height:250,borderRadius:10,margin:5}}></img>
                   <button style={{margin:5}}>
                      Hostel Made</button>
                   </div>
                   <div style={{display:"flex",flexDirection:"column"}}>
                   <img src={"https://i.postimg.cc/yNMc0Csn/Screenshot-2022-06-03-at-5-32-08-PM.png"} style={{width:180,height:250,borderRadius:10,margin:5}}></img>
                   <button style={{margin:5}}>
                      Hostel Made
                       </button>
                      </div>
                       </div> 
                </Col>
                <Col md={12}>
                   <div data-aos="slide-down" >
                   <h4 style={{textAlign:"center",marginTop:10,margin:10,paddingLeft:5,paddingRight:5}}>Proper Indian Lunch of 6 to 8 chapatis with rajma/chana/soyabeans and rice with dal</h4>
                  
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Calories</p>
                      <div style={{fontSize:30,color:'green'}}>
                      <AnimatedNumber value={800} formatValue={formatValue} duration={3000} />
                      Kcal
                      </div>
                   </div>
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Protein</p>
                      <div style={{fontSize:30,color:"#ff7043"}}>
                      <AnimatedNumber value={12} formatValue={formatValue} duration={3000} />
                      g
                      </div>
                   </div>
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Fat</p>
                      <div style={{fontSize:30,color:"#f44336"}}>
                      <AnimatedNumber value={40} formatValue={formatValue} duration={3000} />
                     g
                      </div>
                   </div>
                       </div> 
                </Col>
            </Row>
            <div style={{width:90+"%",borderWidth:0.1,borderStyle:"solid",borderColor:"darkslategray",marginBottom:10}}></div>
            <h2 style={{color:"goldenrod"}}>Pre and Post Workout</h2>
            <Row style={{display:"flex",flexDirection: width<768 ? "column":"row",justifyContent:"space-evenly",alignItems:"center",width:80+"%",margin:20}}>
                <Col  md={12} >
                   <div data-aos="flip-left" style={{display:"flex",flexDirection:"row"}}>
                  <div style={{display:"flex",flexDirection:"column"}}>
                   <img src={"https://i.postimg.cc/nLSG1cDy/51-LV3-Ovs4-ZL-SX300-SY300-QL70-FMwebp.webp"} style={{width:180,height:250,borderRadius:10,margin:5}}></img>
                   <button style={{margin:5}}>
                      <a target={"_blank"} href="https://www.amazon.in/Nutritius-Peanut-Butter-Chikki-Packs/dp/B07BMN8DCB/ref=asc_df_B07BMN8DCB/?tag=googleshopdes-21&linkCode=df0&hvadid=397079577752&hvpos=&hvnetw=g&hvrand=12016602394257194689&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9151132&hvtargid=pla-835352286456&psc=1&ext_vrnc=hi">Checkout</a>
                      </button>
                   </div>
                   <div style={{display:"flex",flexDirection:"column"}}>
                   <img src={"https://i.postimg.cc/SN46R4xG/Screenshot-2022-06-03-at-5-04-36-PM.png"} style={{width:180,height:250,borderRadius:10,margin:5}}></img>
                   <button style={{margin:5}}>
                       <a target={"_blank"} href="https://nutrabay.com/product/nutrabay-pure-100-soy-protein-isolate">Checkout</a></button>
                   </div>
                       </div> 
                </Col>
                <Col md={12}>
                   <div data-aos="slide-down"  >
                   <h4 style={{textAlign:"center",marginTop:10,margin:10,paddingLeft:5,paddingRight:5}}>A high calorie, high protein chikki, that is high in sugar just before workout to push the energy levels high.</h4>
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Calories</p>
                      <div style={{fontSize:30,color:'green'}}>
                      <AnimatedNumber value={400} formatValue={formatValue} duration={3000} />
                      Kcal
                      </div>
                   </div>
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Protein</p>
                      <div style={{fontSize:30,color:"#ff7043"}}>
                      <AnimatedNumber value={50} formatValue={formatValue} duration={3000} />
                      g
                      </div>
                   </div>
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Fat</p>
                      <div style={{fontSize:30,color:"#f44336"}}>
                      <AnimatedNumber value={30} formatValue={formatValue} duration={3000} />
                     g
                      </div>
                   </div>
                       </div> 
                </Col>
            </Row>
            <div style={{width:90+"%",borderWidth:0.1,borderStyle:"solid",borderColor:"darkslategray",marginBottom:10}}></div>
            <h2 style={{color:"goldenrod"}}>Dinner</h2>
            <Row style={{display:"flex",flexDirection: width<768 ? "column":"row",justifyContent:"space-evenly",alignItems:"center",width:80+"%",margin:20}}>
                <Col  md={12} >
                   <div data-aos="flip-left" style={{display:"flex",flexDirection:"row"}}>
                  <div style={{display:"flex",flexDirection:"column"}}>
                   <img src={"https://i.postimg.cc/jSL57xRw/41t-Onk-Lsbe-L-SX300-SY300-QL70-FMwebp.webp"} style={{width:180,height:250,borderRadius:10,margin:5}}></img>
                   <button style={{margin:5}}>
                       <a target={"_blank"} href="https://www.amazon.in/dp/B071NKV55W?ref=nb_sb_ss_w_as-reorder_k0_1_6&amp=&crid=2Q96RRS4BIIQ3&sprefix=alpino&th=1">Checkout</a></button>
                   </div>
                   <div style={{display:"flex",flexDirection:"column"}}>
                   <img src={"https://i.postimg.cc/44Sj3s2F/Vegan1.webp"} style={{width:180,height:250,borderRadius:10,margin:5}}></img>
                   <button style={{margin:5}}>
                       <a target={"_blank"} href="https://www.thehealthfactory.in/products/multi-protein-lite-vegan">Checkout</a>
                       </button>
                      </div>
                       </div> 
                </Col>
                <Col md={12}>
                   <div data-aos="slide-down" >
                   <h4 style={{textAlign:"center",marginTop:10,margin:10,paddingLeft:5,paddingRight:5}}>Very tasty and high in protien peanut butter and vegan bread right after workout to refill.</h4>
                 
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Calories</p>
                      <div style={{fontSize:30,color:'green'}}>
                      <AnimatedNumber value={900} formatValue={formatValue} duration={3000} />
                      Kcal
                      </div>
                   </div>
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Protein</p>
                      <div style={{fontSize:30,color:"#ff7043"}}>
                      <AnimatedNumber value={25} formatValue={formatValue} duration={3000} />
                      g
                      </div>
                   </div>
                   <div style={{display:"flex",flexDirection:"row",justifyContent:'center',alignItems:"center"}}>
                      <p style={{margin:10,fontSize:30}}>Fat</p>
                      <div style={{fontSize:30,color:"#f44336"}}>
                      <AnimatedNumber value={40} formatValue={formatValue} duration={3000} />
                     g
                      </div>
                   </div>
                       </div> 
                </Col>
            </Row>
            <div style={{width:90+"%",borderWidth:0.1,borderStyle:"solid",borderColor:"darkslategray",marginBottom:10}}></div>
            <h2 style={{color:"goldenrod"}}>Other Healthy Items</h2>
            <Row style={{display:"flex",flexDirection: width<768 ? "column":"row",justifyContent:"space-evenly",alignItems:"center",width:80+"%",margin:20}}>
                <Col  md={12} >
                   <div  style={{display:"flex",flexDirection:"row"}}>
                  <div data-aos="slide-left" style={{display:"flex",flexDirection:"column",margin:5}}>
                   <img src={"https://i.postimg.cc/DzjQJBc6/Screenshot-2022-06-03-at-6-15-58-PM.png"} style={{width:180,height:250,borderRadius:10,margin:5}}></img>
                   <button style={{margin:5}}>
                       <a href="https://www.bigbasket.com/pd/30009284/fresho-avocado-imported-medium-1-pc/">Checkout</a></button>
                   </div>
                   <div data-aos="slide-right" style={{display:"flex",flexDirection:"column",margin:5}}>
                   <img src={"https://i.postimg.cc/mZqd62Cj/Screenshot-2022-06-03-at-6-18-35-PM.png"} style={{width:180,height:250,borderRadius:10,margin:5}}></img>
                   <button style={{margin:5}}>
                       <a href="https://www.indiamart.com/proddetail/mix-dry-fruits-17624692062.html">Checkout</a>
                       </button>
                      </div>

                      </div> 
                </Col>

            </Row>

        </div>
    )
}

export default Diet
