import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import {
  dataabout,
  meta,
  worktimeline,
  skills,
  services,
} from "../../content_option";

export const About = () => {
  return (
    <HelmetProvider>
      <Container className="About-header">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Terms and conditions</title>
          <meta name="description" content={"Terms and conditions to avail Rahul Mattihalli Service"} />
        </Helmet>
        <Row className="mb-5 mt-3">
          <Col lg="8">
            <h1 className="display-4 mb-4">Terms and conditions</h1>
            <hr className="t_border my-4 ml-0 text-left" />
          </Col>
        </Row>
        <Row className="sec_lp">
          <Col lg="12" className="d-flex align-items-center">
            <div>
              <p>{"1. I agree to abide by the rules of conduct,behavior and be respectful during the session."}</p>
            </div>
          </Col>
        </Row>
        <Row className="sec_lp">
          <Col lg="12" className="d-flex align-items-center">
            <div>
            <p>{"2. I understand that this is not professional consultation service and consultant is not professionally certified to provide advice on fitness."}</p>
            </div>
          </Col>
        </Row>
        <Row className="sec_lp">
          <Col lg="12" className="d-flex align-items-center">
            <div>
              <p>{"3. I acknowledge that this consultation session is not a substitute for doctor consultation or physician consultation, and suggestions in the session are to be taken with caution and consultant holds no responsibility whatsoever. "}</p>
            </div>
          </Col>
        </Row>
        <Row className="sec_lp">
          <Col lg="12" className="d-flex align-items-center">
            <div>
              <p>{"4. Any Personal Plan will have been prepared based on information provided by you. You are responsible for the accuracy of any information that you provide to us. You are responsible for informing us of any health issues or medical conditions when asking us to prepare a Personal Plan."}</p>
            </div>
          </Col>
        </Row>
        <Row className="sec_lp">
          <Col lg="12" className="d-flex align-items-center">
            <div>
              <p>{" 5. The results, if any, from Personal training advice  and/or nutrition advice may vary from person to person. Further, engaging in any Exercise, Fitness, Personal Training, or Nutrition program involves risks of injury. If you choose to follow any of my Fitness’s Personal advice or nutrition recommendations, you acknowledge this and agree not to hold Rahul Mattihalli, or any other persons associated with Rahul Mattihalli for any injuries or damages you may incur."}</p>
            </div>
          </Col>
        </Row>

        <Row className="sec_lp">
          <Col lg="12" className="d-flex align-items-center">
            <div>
              <p>{" 6. Any terms or conditions that create an inconsistency with any legislation are considered void to the extent of the inconsistency."}</p>
            </div>
          </Col>
        </Row>

        <Row className="sec_lp">
          <Col lg="12" className="d-flex align-items-center">
            <div>
              <p>{" This agreement applies to all clients who book a consultation session with Rahul Mattihalli."}</p>
            </div>
          </Col>
        </Row>
       
      </Container>
    </HelmetProvider>
  );
};
